import React from 'react';

const GithubIcon = props => (
  <svg fill="none" viewBox="0 0 64 64" {...props}>
    <path
      d="M32 .792c-17.68 0-32 14.328-32 32 0 14.141 9.168 26.133 21.88 30.36 1.6.301 2.187-.688 2.187-1.539 0-.76-.027-2.773-.04-5.44-8.902 1.931-10.779-4.293-10.779-4.293-1.456-3.693-3.56-4.68-3.56-4.68-2.899-1.984.224-1.944.224-1.944 3.213.224 4.901 3.296 4.901 3.296 2.854 4.893 7.491 3.48 9.32 2.661.288-2.069 1.112-3.48 2.027-4.28-7.107-.8-14.576-3.552-14.576-15.813 0-3.493 1.24-6.347 3.293-8.587-.36-.808-1.44-4.061.28-8.469 0 0 2.68-.859 8.8 3.28 2.56-.712 5.28-1.064 8-1.08 2.72.016 5.44.368 8 1.08 6.08-4.139 8.76-3.28 8.76-3.28 1.72 4.408.64 7.661.32 8.47 2.04 2.24 3.28 5.093 3.28 8.586 0 12.293-7.48 15-14.6 15.787 1.12.96 2.16 2.922 2.16 5.92 0 4.282-.04 7.722-.04 8.762 0 .84.56 1.84 2.2 1.52C54.84 58.912 64 46.912 64 32.792c0-17.672-14.328-32-32-32"
      fill="#07323b"
    />
  </svg>
);

export default GithubIcon;
